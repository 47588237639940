import InfoRow from "../common/info-row";
import Divider from '@material-ui/core/Divider';


import React from "react";


import "./license-info-details.scss";

const LicenseDetails = (props) => {
  const lic = props.data;
  
  var sn="";
  var sbc="";

  if(lic.license.details.serial_numbers){

      lic.license.details.serial_numbers.map((item,i) => {
        if(i===0)
          sn = item;
        else if(i>0 && i < 4)
          sn = sn + " " + item;
        else if (i === 4)
          sn = sn + " ... "
      });

      
  }
   
  if(lic.license.details.sales_box_codes){

    lic.license.details.sales_box_codes.map((item,i) => {
      if(i===0)
        sbc = item;
      else if(i>0 && i < 4)
        sbc = sbc + " " + item;
      else if (i === 4)
          sn = sn + " ... "
      
    });

    
  }


    return (
      <div>
      <h4>License data</h4>
        <InfoRow label={"License Description"} value={lic.license.description} />
        <Divider />
        <InfoRow label={"License Id"} value={lic.license.license_id} />
        <Divider />
        <InfoRow label={"Application"} value={lic.license.app_name} />
        <Divider />
        <InfoRow label={"App_id"} value={lic.license.app_id} />
        <Divider />
        <InfoRow label={"Serial Numbers"} value={sn} />
        <Divider />
        <InfoRow label={"Sales box codes"} value={sbc} />
        <Divider />
        <InfoRow label={"Created time"} value={lic.created_time} />
        <Divider />
        <InfoRow label={"Signature time"} value={lic.signature_time} />
        <Divider />
        <InfoRow label={"Signature validation"} value={(lic.signature_valid===true) ? "true" : "false" } />
        <Divider />
        <InfoRow label={"Owner"} value={lic.owner} />
        <Divider />
        <InfoRow label={"Creater account"} value={lic.creater_account} />
        <Divider />
        <InfoRow label={"Disabled"} value={(lic.disabled===true) ? "true" : "false" } />
        <Divider />
        <InfoRow label={"Deleted"} value={(lic.deleted===true) ? "true" : "false" } />
        <Divider />
        
        </div>
    );
}

export default LicenseDetails;