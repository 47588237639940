import { Typography } from "@material-ui/core";
import React from "react";

import "./info-row.scss";

const InfoRow = ({ label, value }) => {
    return (
        <div className="info-row">
            <Typography variant="subtitle2">
                {label}
            </Typography>
            <Typography variant="h6">
                {value ? value : "-"}
            </Typography> 
        </div>
    );
}

export default InfoRow;