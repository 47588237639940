export const reduce = (state, action) => {
    switch (action.type) {
        case "LICENSE_STATE_CHANGED": {
            
            const licensesData = action.payload;
            //console.log("licenses", JSON.stringify(licensesData));
            const filteredNewData = licensesData.licenses.reduce((unique, o) => {
                if (!unique.some(obj => obj.license.license_id === o.license.license_id)) {
                    
                    unique.push(o);
                }
                
                return unique;
            }, []);
            
            return {
                ...state,
                licenses: filteredNewData,
                page: licensesData.page,
                totalCount: filteredNewData.length,
            }
        }
        case "SET_SUMMARY_DATA": {
            return { ...state, summaryData: action.payload };
        }
        case "SET_NOTIFICATION_DATA": {
            const { notificationData } = state;
            let newData = action.payload;

            if (notificationData) {
                newData = [...newData, ...notificationData];
            }

            // Remove duplicates by id
            const filteredNewData = newData.reduce((unique, o) => {
                if (!unique.some(obj => obj.license_id === o.license_id)) {
                    unique.push(o);
                }
                return unique;
            }, []);

            return { ...state, notificationData: filteredNewData };
        }
        default:
            return state;
    }
}