import React, { createContext, useReducer, useContext } from "react";
import { reduce } from "./LicenseReducer";

export const initialState = {
    licenses: [],
    summaryData: undefined,
    perPage: 20,
    totalCount: 0,
    page: 1,
};

const LicenseContext = createContext([initialState, () => null]);

export const LicenseStateProvider = ({ children, initState }) => {
    const [state, dispatch]  = useReducer(reduce, initState || initialState);

    return <LicenseContext.Provider value={[state, dispatch]}>{children}</LicenseContext.Provider>;
}

export const useLicenseState = () => {
    const [licensesState, dispatch] = useContext(LicenseContext);

    return { licensesState, dispatch }
}