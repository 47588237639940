import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import LicensePage from './components/license/license-page';
import LicenseInfoPage from './components/license/license-info-page';
import LicenseNewPage from './components/license/license-new-page';
import DeviceMngPage from './components/devices/device-mng-page';
import NavBar from './components/common/navbar';
import LoginPage from './components/login/login-page';
import { useAuthState } from "./state/AuthState";
import { useEffect } from "react"; 
import { REFRESH_TOKEN, ACCESS_TOKEN, USER } from "./constants";
import { LicenseStateProvider, initialState } from './state/LicenseState'

const PrivateRoute = ({ component, path, exact }) => {
    const { state } = useAuthState();
    const { dispatchAuth } = useAuthState();
    const history = useHistory();

    const refresh_token = localStorage.getItem(REFRESH_TOKEN);
    const access_token = localStorage.getItem(ACCESS_TOKEN);
    const username = localStorage.getItem(USER);
    const signedIn = (state && state.access_token) || access_token;

    useEffect(() => {
        
        if (!access_token) {
            history.push("/login");
        } else {
            dispatchAuth({ type: "sign_in", payload: { username, access_token, refresh_token } });
        }
    }, [username, access_token, refresh_token]);

    return signedIn ?
        (<Route
            path={path}
            exact
            component={component}
        />) : <Redirect to="/login" />
}

const AuthenticatedApp = () => {
    const history = useHistory();
    const { dispatchAuth } = useAuthState();

    const onSignOut = () => {
        dispatchAuth({ type: "sign_out" });
        history.push("/login");
    }

    const NotFound = () => (
        <div style={{ marginTop: 80 }}>
            <Typography variant="h2">Not found!</Typography>
            <Button color="primary" variant="contained" onClick={() => history.push("/login")} style={{ marginTop: 16 }}>
                Login page
            </Button>
        </div>
    );

    return (
        <div>
            <LicenseStateProvider value={initialState}>
                <NavBar onSignOut={onSignOut} />
                <Switch>
                    <Route exact path="/login">
                        <LoginPage />
                    </Route>
                    <PrivateRoute exact path="/" component={LicensePage} />
                    <PrivateRoute exact path="/licenses" component={LicensePage} />
                    <PrivateRoute exact path="/licenses/new" component={LicenseNewPage} />
                    <PrivateRoute exact path="/licenses/:licenseId" component={LicenseInfoPage} />
                    <PrivateRoute exact path="/devices" component={DeviceMngPage} />
                    <Route component={NotFound}/>
                </Switch>
            </LicenseStateProvider>
        </div>

    );
}

export default AuthenticatedApp;