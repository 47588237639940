import React, { useState } from "react";
import { CircularProgress, Typography} from "@material-ui/core";
import { useEffect } from "react";
import { serverData } from "../../data/api";
import { useAuthState } from "../../state/AuthState";
import { useLicenseState } from "../../state/LicenseState";
import LicenseList from "../license/license-list";
import "./license-page.scss";


const LicensePage = () => {
    
    const { state, dispatchAuth } = useAuthState();
    const [licensesLoading, setLicensesLoading] = useState(true);
    const { licensesState, dispatch } = useLicenseState();
    const [searchWord, setSearchWord] = useState("");
    const [perPage, setPerPage] = useState(20);
    const [page, setPage] = useState(1);

    useEffect(() => {
      serverData(`/lion/licensemngapi/v1/licenses`,{},'GET',true,dispatchAuth,(json,status)=>{
          setLicensesLoading(false);
        
          if (status===200) {
              dispatch({ type: "LICENSE_STATE_CHANGED", payload: {licenses:json,page:1} });
          }
      })
    },[]);

   

    const handleSearchChange = (event) => {
        setSearchWord(event.target.value);
    }

   
    return (
    
      <div className="license-page">
        <Typography  className="title" style={{ margin: 24, alignSelf: "flex-start" }} variant="h5">
          <b>Licenses</b>
        </Typography>          
          {
            licensesLoading ? 
            <CircularProgress style={{ marginLeft: "auto", marginRight: "auto" }} />
              : 
            <LicenseList searchWord={searchWord} setPerPage={setPerPage} setPage={setPage} perPage={perPage} page={page}/>
          }
      </div>
    );
}

export default LicensePage;
