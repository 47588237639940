import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';


import React from "react";


import "./license-info-usage-history.scss";


const EventRow = (props) =>{
  return(
      <TableRow key={props.index}>
        <TableCell>{props.event.time}</TableCell>
        <TableCell>{props.event.serial_number}</TableCell>
        <TableCell>{props.event.event_str}</TableCell>
      </TableRow>);
}

const LicenseUsageHistory=(props) => {
  const data = props.data;
console.log(props);
    return (
      <div key="events">
        <h4>Usage History</h4>

        <Table style={{ minWidth: 300 }}>
        <TableHead>
          <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Serial Number</TableCell>
          <TableCell>Event Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            ( data === null ) ? <div>No data</div> :
            data.map((d,index) => <EventRow event={d} index={index}/>) 
            }
        </TableBody>
      </Table>


      </div> 
    );
}
               
export default LicenseUsageHistory;