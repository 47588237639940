import React, { useState } from "react";
import { Typography, Snackbar,SnackbarContent ,CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import { serverData } from "../../data/api";
import { useAuthState } from "../../state/AuthState";
import { useHistory } from "react-router-dom";
import { Container, Paper, TextField, Button, Select ,MenuItem,TableRow,TableCell} from '@material-ui/core';
import LicenseForm from './license-form';
import "./license-new-page.scss";


function isDateBeforeToday(date) {
  return new Date(date.toDateString()) < new Date(new Date().toDateString());
}

const LicenseNewPage = () => {
    const { state, dispatchAuth } = useAuthState();
    const [errorMsg, setErrorMsg] = useState("");
    const [dataLoading, setDataLoading] = useState(true);
    const [appNameArray, setAppNameArray] = useState([]);
    const [appIdArray, setAppIdArray] = useState([]);
    const [selectedAppName, setSelectedAppName] = useState("");
    const [selectedAppId, setSelectedAppId] = useState("");
    
    const [sbc, setSbc] = useState("");
    const [sn, setSn] = useState("");
    const [desc, setDesc] = useState("");
    const [expires, setExpires] = useState("");
    const history = useHistory();
    const [errorSnackOpen, showErrorSnack] = useState(false);

    const [serialNumberArray, setSerialNumberArray] = useState([]);
    const [salesBoxCodeArray, setSalesBoxCodeArray] = useState([]);

    useEffect(() => {

    serverData(`/lion/licensemngapi/v1/apps`,{},'GET',true,dispatchAuth,async (json,status)=>{
        console.log("Get Apps done start "+status);
        setDataLoading(false);
        //setAppDetails(json);
        json.forEach(element => {
          //console.log(JSON.stringify(element));
          setAppNameArray(prevArray => [...prevArray, element.app_name])
          setAppIdArray(prevArray => [...prevArray, element.app_id])
        });
        setSelectedAppName(json[0].app_name);
        setSelectedAppId(json[0].app_id);
        /*console.log(JSON.stringify(json[0]));*/
    })
  },[]);

  const createLicense = () => {
    var appid = appNameArray.indexOf(selectedAppName);

    var licenseData = {
          app_id:appIdArray[appid],
          description:desc,
          expiration:expires,
          serial_numbers:serialNumberArray,
          sales_box_codes:salesBoxCodeArray,
        };
       
        serverData(`/lion/licensemngapi/v1/licenses`,licenseData,'POST',true,dispatchAuth,(resp,status)=>{
          console.log("create license done start " +status);
  
          if (status === 200) {
            console.log("create license done start " +JSON.stringify(resp));
            history.push("/licenses/"+resp.id);
          } else {
            setErrorMsg("Error saving license to server");
            showErrorSnack(true);
          }
      
      })
  }

  const onClick = () => {

    const isAppIdEmpty = selectedAppName === "";
    const isExpiresEmpty = expires === "";
    const isDescEmpty = desc === "";
    let dataValid = true;

    if (isAppIdEmpty) {
        setErrorMsg("Application not selected");
        dataValid = false;
    }

    if (serialNumberArray.length===0 && salesBoxCodeArray.length===0) {
      setErrorMsg("Serial number / sales box code missing");
      dataValid = false;
    }

    if (isExpiresEmpty) {
      setErrorMsg("Expiration time missing");
      dataValid = false;
    }
    console.log("exp:"+new Date(expires));
    console.log("now:"+ Date());

    if (isDateBeforeToday(new Date(expires))) {
      setErrorMsg("Expiration time must be in future");
      dataValid = false;
    }

    if (isDescEmpty) {
      setErrorMsg("Description field missing");
      dataValid = false;
    }

    if(dataValid){
      createLicense();
    }else{
      showErrorSnack(true);
    }
}

  const handleAppListChange = (event) => {
    setSelectedAppName(event.target.value);
    setSelectedAppId(appIdArray[appNameArray.indexOf(event.target.value)]);
  }

  const handleSbcChange = (event) => {
    setSbc(event.target.value);
  }
  
  const handleSnChange = (event) => {
    setSn(event.target.value);
  }
  const handleDescChange = (event) => {
    setDesc(event.target.value);
  }
  const handleExpiresChange = (event) => {
    setExpires(event.target.value);
  }
  
  const handleSnackClose = (event) => {
    showErrorSnack(false);
  }

  const onSbcKeyPress=(e) => {
    if (e.key === 'Enter') {
      setSalesBoxCodeArray(prevArray => [...prevArray, sbc])
      e.target.value = "";
    }
  } 
  const onSnKeyPress=(e) => {
    if (e.key === 'Enter') {
      setSerialNumberArray(prevArray => [...prevArray, sn])
      e.target.value = "";
    }
  } 

  const onClickSn=(sn_in) => {
    const newSerialNumberArray = serialNumberArray.filter((sn_) => sn_ !== sn_in);
    setSerialNumberArray(newSerialNumberArray);
  } 

  const onClickSbc=(sbc_in) => {
    const newSalesBoxCoderArray = salesBoxCodeArray.filter((sbc_) => sbc_ !== sbc_in);
    setSalesBoxCodeArray(newSalesBoxCoderArray);
  } 


    return (
      <Container  className="license-new-page">
          <Paper style={{ padding: 8 , margin:10}}>
          {dataLoading ? <CircularProgress style={{ marginLeft: "auto", marginRight: "auto" }} /> :
          <div><TableRow>
              <Typography className="title" style={{ margin: 24, alignSelf: "flex-start" }} variant="h4">
                  <b>Create New License</b>
              </Typography>
          </TableRow>
          <TableRow>
            <TableCell>
              <Select
                  margin="normal" 
                  variant="outlined"
                  label="Applications"
                  value={selectedAppName}
                  onChange={handleAppListChange}
                  inputProps={{
                    name: "Application list",
                    id: "app-id"
                  }}
                >
                {appNameArray.map((value, index) => {
                  return <MenuItem key={index} value={value}>{value}</MenuItem>;
                })}
              </Select><br></br>
              <TextField data-lpignore="true" margin="normal" variant="outlined" label="Sales Box Code" type="text" name="sales_box_code" onChange={handleSbcChange} onKeyPress={onSbcKeyPress}/><br></br>
              <TextField data-lpignore="true" margin="normal" variant="outlined" label="Serial Number" type="text" name="serial_number" onChange={handleSnChange} onKeyPress={onSnKeyPress}/><br></br>
              <TextField data-lpignore="true" margin="normal" variant="outlined" label="Description" type="text" name="description" onChange={handleDescChange}/><br></br>
              <TextField
                  id="date"
                  label="expires"
                  type="date"
                  defaultValue={Date.now()}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleExpiresChange}
              />
                <Snackbar
                      open={errorSnackOpen}
                      autoHideDuration={5000}
                      onClose={handleSnackClose}>
             
                      <SnackbarContent style={{
                        backgroundColor:'maroon',
                      }}
                      message={<span id="client-snackbar">{errorMsg}</span>}
                      />
                  </Snackbar>
            </TableCell>
            <TableCell>
              <LicenseForm 
              onSnClick={(sn_temp) => onClickSn(sn_temp)}
              onSbcClick={(sbc_temp) => onClickSbc(sbc_temp)}
              sn_array={serialNumberArray}
              sbc_array={salesBoxCodeArray}
              description={desc}
              app_name={selectedAppName}
              app_id={selectedAppId}
              expires={expires}
              />
            </TableCell>
          </TableRow>
            <TableRow>
                  <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => onClick()}
                        style={{ marginTop: 8 }}
                    >
                        Create License
                  </Button>
              </TableRow>
              </div>
            }
            </Paper>
      </Container>
    );
}

export default LicenseNewPage;


