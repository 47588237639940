import React, { useState } from "react";
import { CircularProgress, Typography} from "@material-ui/core";
import { useEffect } from "react";
import { serverData } from "../../data/api";
import { useAuthState } from "../../state/AuthState";
import { useLicenseState } from "../../state/LicenseState";
import {  Button } from '@material-ui/core';
import LicenseList from "../license/license-list";
import { useHistory } from "react-router-dom";
import "./device-mng-page.scss";


const DeviceMngPage = () => {
  const history = useHistory();
    
  const { state, dispatchAuth } = useAuthState();
    const [licensesLoading, setLicensesLoading] = useState(true);
    const { licensesState, dispatch } = useLicenseState();
    const [searchWord, setSearchWord] = useState("");
    const [perPage, setPerPage] = useState(20);
    const [page, setPage] = useState(1);

    useEffect(() => {
      /*serverData(`/lion/licensemngapi/v1/licenses`,{},'GET',true,dispatchAuth,(json,status)=>{
          setLicensesLoading(false);
        
          if (status===200) {
              dispatch({ type: "LICENSE_STATE_CHANGED", payload: {licenses:json,page:1} });
          }
      })*/
    },[]);

 
   
    return (
    
      <div className="license-page">
        <Typography style={{ margin: 24, alignSelf: "flex-start" }} variant="h3">
          <b>Device Management</b>
        </Typography>
         
      </div>
    );
}

export default DeviceMngPage;
