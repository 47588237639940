import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import {  Button} from '@material-ui/core';
import React from "react";


import "./license-form.scss";

const LicenseRaw = (props) => {
  const data = props.licensejson;

  return (
    <div>
      License file 
      <table className='licenseTable'>
        <tr><th>{'{'}</th><th></th><th></th><th></th><th></th></tr>
        <tr><th></th><th>version:</th><th>1</th><th></th><th></th></tr>
        <tr><th></th><th>license_id:</th><th>'na'</th><th></th><th></th></tr>
        <tr><th></th><th>app_id:</th><th>{data.app_id}</th><th></th><th></th></tr>
        <tr><th></th><th>app_name:</th><th>{data.app_name}</th><th></th><th></th></tr>
        <tr><th></th><th>description:</th><th>{data.description}</th><th></th><th></th></tr>
        <tr><th></th><th>details: {'{'}</th><th></th><th></th><th></th></tr>
        <tr><th></th><th></th><th>serial_number:[</th><th></th><th></th></tr>
        {
          data.details.serial_number.map((item) =>    
            <tr><th></th><th></th><th></th><th>
              {item}
            </th><th>
            <Button variant="outlined" startIcon={<DeleteIcon/>} onClick={()=>props.onSnClick(item)} />
              </th></tr>
          )
        }
        <tr><th></th><th></th><th>]</th><th></th><th></th></tr>
        <tr><th></th><th></th><th>sales_box_code:[</th><th></th><th></th></tr>
        {
           data.details.sales_box_code.map((item) =>    
           <tr><th></th><th></th><th></th><th>
             {item}
            </th><th>
            <Button variant="outlined" startIcon={<DeleteIcon/>} onClick={()=>props.onSbcClick(item)} />
            </th></tr>
           )
        }
        <tr><th></th><th></th><th>]</th><th></th><th></th></tr>
        <tr><th></th><th></th><th>expires:</th><th>{data.details.expires}</th><th></th></tr>
        <tr><th></th><th>{'}.'}</th><th></th><th></th><th></th></tr>
        <tr><th>{'}'}</th><th></th><th></th><th></th><th></th></tr>
      </table>      
  </div>  
  );
}
const LicenseForm = (props) => {


    const licenseJson={
      details: {
        serial_number:[],
        sales_box_code:[],
        expires:'',
         },
      version: '1',
      license_id: 'Defined later',
      app_id: 'na',
      app_name: 'na',
      description: 'na'
    }
    
    if(props.sn_array){
      licenseJson.details.serial_number =props.sn_array;
    }

    if(props.sbc_array){
      licenseJson.details.sales_box_code =props.sbc_array;
    }

    if(props.expires){
      licenseJson.details.expires =props.expires;
    }

    if(props.app_id){
      licenseJson.app_id =props.app_id;
    }

    if(props.app_name){
      licenseJson.app_name =props.app_name;
    }

    if(props.description){
      licenseJson.description =props.description;
    }

    /*
    <TableRow>
        <TableCell><SerialNumbers sn={props.sn_array} onClick={props.onSnClick}/></TableCell>
        <TableCell><SalesBoxCode sbc={props.sbc_array} onClick={props.onSbcClick}/></TableCell>
      </TableRow>
    */
    return (
      <div>
       <TableRow> 
         <LicenseRaw 
          licensejson={licenseJson}
          onSbcClick={props.onSbcClick}
          onSnClick={props.onSnClick}
         />
      
      </TableRow>
      <TableRow>  
      </TableRow> 
      </div> 
    );
}

export default LicenseForm;