import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import React from "react";
import { useHistory } from "react-router-dom";

import "./license-row.scss";

const LicenseRow = ({ license }) => {
    const history = useHistory();
    var snStr="";
    var sbcStr="";
  
    if(license.license.details.serial_numbers){
      snStr = license.license.details.serial_numbers;

        if(license.license.details.serial_numbers.length > 2)
        snStr = license.license.details.serial_numbers[0]+" "
          + license.license.details.serial_numbers[1]+" ..."
          ;
    }
     
    if(license.license.details.sales_box_codes){
      sbcStr = license.license.details.sales_box_codes;
  
      if(license.license.details.sales_box_codes.length > 2)
      sbcStr = license.license.details.sales_box_codes[0]+" "
        + license.license.details.sales_box_codes[1]+" ..."
        ;
    }


    const openLicenseInfo = () => {
        console.log("openLicenseInfo");
        history.push(`/licenses/${license.license.license_id}`);
    }
    //console.log(license);
    return (
        <TableRow
        className="license-row"
            onClick={() => openLicenseInfo()}
            key={license.license.license_id}>
            <TableCell align="center" component="th" scope="row">
               {(license.disabled === true || license.signature_valid === false || license.deleted === true )?
               <CancelIcon color="secondary"/>:<CheckCircleIcon color="primary"/>
               }
            </TableCell>
            <TableCell align="center" component="th" scope="row">
               {license.license.app_name}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
               {license.license.description}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
               {license.license.details.expires}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
               {snStr}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
               {sbcStr}
            </TableCell>
        </TableRow>
    );
}

export default LicenseRow;