import React, { useState } from "react";
import { CircularProgress, Typography, Snackbar,  } from "@material-ui/core";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MuiAlert from '@material-ui/lab/Alert';
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuthState } from "../../state/AuthState";
import { useLicenseState } from "../../state/LicenseState";
import { Container, Paper, TableContainer, Button,Table,TableRow,TableCell } from '@material-ui/core';
import { serverData,baseUrl } from "../../data/api";
import  LicenseUsageHistory  from "./license-info-usage-history";
import  LicenseDetails  from "./license-info-details";
import {  ACCESS_TOKEN } from "../../constants";
import {ConfirmationDialog} from "../common/dialog"

import "./license-page.scss";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LicenseInfoPage = () => {
    const history = useHistory();
    const { state, dispatchAuth } = useAuthState();
    const [licenseLoading, setLicenseLoading] = useState(true);
    const [errorSnackOpen, showErrorSnack] = useState(false);
    const [snackBarText, setSnackBarText] = useState("");
    const [licenseData, setLicenseData] = useState(null);
    const [licenseEvents, setLicenseEvents] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const { licenseId } = useParams();
    useEffect(() => {

       serverData(`/lion/licensemngapi/v1/licenses/`+licenseId,{},'GET',true,dispatchAuth,(resp,status)=>{
          
          if(status===200){
            setLicenseData(resp);
            setLicenseLoading(false);
          }
      })
       serverData(`/lion/licensemngapi/v1/licenses/`+licenseId+'/events',{},'GET',true,dispatchAuth,(response,status)=>{
          
          if(status===200){
            setLicenseEvents(response);
          }
      })

  },[]);

  const downloadLicenseFile = (event) => {
    console.log("License "+licenseData.license.license_id);
    const url = baseUrl+`/lion/licensemngapi/v1/licenses/`+licenseData.license.license_id+'/file?token='+localStorage.getItem(ACCESS_TOKEN);
    console.log(url);
    window.open(url, "_blank")
  };

  const dialogCallback=(state) => {

    if(state) {
      serverData(`/lion/licensemngapi/v1/licenses/`+licenseData.license.license_id,{},'DELETE',true,dispatchAuth,(resp,status)=>{
        if(status===200){
          console.log("License file deleted");
          history.push(`/licenses`);
        }
      })
    } 
    setConfirmDialogOpen(false);
  };

  const deleteLicense = (event) => {
    console.log("delete pressed");
   
    setConfirmDialogOpen(true);
  };

  const enableLicense = (event,state) => {
    console.log("License "+licenseData.license.license_id);

    if(state === true && licenseData.disabled === false){
      return;
    }

    if(state === false && licenseData.disabled === true){
      return;
    }
    var put_body = {
      disabled:!state,
    }

    serverData(`/lion/licensemngapi/v1/licenses/`+licenseData.license.license_id,put_body,
    'PUT',true,dispatchAuth,(resp,status)=>{

      if(status===200){
        console.log("License file disabled/deleted ");
        //history.push(`/licenses/`+licenseData.license.license_id);
        window.location.reload(false);
      }
    })
  };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        showErrorSnack(false);
    };


    const ButtonRow=(props) => {

      if(licenseData === null)
        return(<div>No actions available</div>)

      if(props.data.deleted===true){
        return(<div></div>)
      }else if(props.data.disabled===true){
        return(<div>
          <Button variant="contained" onClick={(event)=>enableLicense(event,true)}>Enable license</Button>
          <Button variant="contained" onClick={(event)=>deleteLicense(event)}>Delete license</Button>
        </div>)
      }else{
        return(<div>
          <Button variant="contained" onClick={downloadLicenseFile}>Download file</Button>
          <Button variant="contained" onClick={(event)=>enableLicense(event,false)}>Disable license</Button>
          <Button variant="contained" onClick={(event)=>deleteLicense(event)}>Delete license</Button>
        </div>);
      }
    } 
  


    return (
        <div className="license-page">
            <Typography className="title" variant="h4">
                <b>License</b>
            </Typography>
            {
            licenseLoading ? 
            <CircularProgress style={{ marginLeft: "auto", marginRight: "auto" }} />
             :
               <div>
                 <ButtonRow data={licenseData}/>

                 <TableContainer sx={{ maxHeight: 440 }}>
               <Table>

                 <TableRow  style={{ verticalAlign: 'top' }} >
                   <TableCell  style={{ width: 500 }}><LicenseDetails data={licenseData}/></TableCell>
                   <TableCell><LicenseUsageHistory data={licenseEvents}/></TableCell>
                 </TableRow>
                 
                 
               </Table>
               </TableContainer>
              </div>
            }
            <Snackbar open={errorSnackOpen} autoHideDuration={3000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity="error">
                    {snackBarText}
            </Alert>
            </Snackbar>
            <ConfirmationDialog callback={dialogCallback} open={confirmDialogOpen} title="Delete License" 
            message="Do you want delete message?"
            />
        </div>
    );
}

export default LicenseInfoPage;
