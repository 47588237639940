import { REFRESH_TOKEN, ACCESS_TOKEN } from "../constants";
export const baseUrl = "https://lionbackend.aavamobile.com";
//export const baseUrl = "https://lic-lion-test.herokuapp.com";
//export const baseUrl = "http://localhost:3002";

export const createAuthMiddleWare = (dispatch,data2) => {
    
    return (res) => {

        if (res.status === 401) {
            //dispatch({ type: "auth_renew", payload: {} });
            //dispatch({ type: "sign_out", payload: {} });
        }
        return res;
        
    };
}

const getDataAsync = (url,body,method,author,retry,dispatch,done) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  var status = 200;
  var httpdata ={};

  if(method==='POST' || method==='PUT') {
    httpdata =  {
      method:method,
      headers: {
      'Content-Type': 'application/json',
      authorization: `bearer ${accessToken}`,
      }, 
      body:JSON.stringify(body),
    }
  } else {
    httpdata =  {
      method:method,
      headers: {
      'Content-Type': 'application/json',
      authorization: `bearer ${accessToken}`,
      }, 
    }
  }

  fetch(`${baseUrl}${url}`,httpdata)
    .then(data => {
          status = data.status;
          if(data.status===401){
            console.log("token expired");
            const refreshToken = localStorage.getItem(REFRESH_TOKEN);
            fetch(`${baseUrl}/lion/licensemngapi/v1/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }, 
                body: JSON.stringify({refresh_token:refreshToken})
            })
            .then((ret)=>{
              console.log("refresh loging "+retry+" status:"+ret.status);
              status = ret.status;
              if(ret.status === 200 && retry===true){
                return ret.json();
              }else
                dispatch({ type: "sign_out", payload: {} });
            })
            .then((ret)=>{
              if(ret){
                console.log("Update new access token to local item");
                localStorage.setItem(ACCESS_TOKEN, ret.access_token);
                localStorage.setItem(REFRESH_TOKEN, ret.refresh_token);
                getDataAsync(url,body,method,author,false,dispatch,done);
              }
            })
          }else{
            
            return data.json();
          }
          return null;
    })
    .then(json=>{
      if(json)
        done(json,status);
    })
    .catch(err => {
          console.error("Error :"+ err);
          done(err,0);
    });
  
};

export const serverData = (_url,_body,_method,_author,_dispatch,_done)=> {
  getDataAsync(_url,_body,_method,_author,true,_dispatch,_done);
};

