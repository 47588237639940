import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import capitalize from "../common/capitalize";
//import TablePagination from '@material-ui/core/TablePagination';
import { useLicenseState } from "../../state/LicenseState";
import LicenseRow from "./license-row";

import "./license-list.scss";


//const DeviceList = ({ searchWord, setPerPage, setPage, perPage, page }) => {
const LicenseList = ({ searchWord, setPerPage, setPage}) => {
  const { licensesState } = useLicenseState();
  const licenses = licensesState.licenses ? licensesState.licenses : [];

  return (
    <TableContainer component={Paper}>
      <Table style={{ minWidth: 550 }}>
        <TableHead >
          <TableRow>
          <TableCell align="center">Valid</TableCell>
          <TableCell align="center">Application</TableCell>
          <TableCell align="center">Description</TableCell>
          <TableCell align="center">Expires</TableCell>
          <TableCell align="center">Serial number</TableCell>
          <TableCell align="center">Sales box code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {licenses &&
            licenses.map((d) => <LicenseRow key={d.license.license_id} license={d} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LicenseList;
